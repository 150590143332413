const DomainValidationMixin = {
    methods: {
        domain_validator(val) {
          return val == "" || /^((?!-)[A-Za-z0-9-_]{1,63}(?!-<)\.)+[0-9A-Za-z-]{2,24}$/.test(val);
        },
        shadow_domain_validator(val) {
            return val == "" || this.domain_validator(val.replace('*.', ''));
        },
        isNumber(num) {
          return /^\d+$/.test(num);
        },
        checkIPFormat(ips) { 
          ips = ips.split(",")
          const result = [];
          ips.forEach(item => {
            item = item.trim()
            let itm = item.split(":")[0];
            const port = item.split(":")[1];
            itm = itm.split(".") ;
            if (itm.every(this.isNumber)) {
              result.push(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(itm.join('.')))
              if (port) {
                result.push(this.isNumber(port));
              }
            } else { 
              // checking ip with scheme
              try {
                let uri = new URL(item);
                let itms = uri.hostname.split('.');
                if (itms.every(this.isNumber)) {
                  result.push(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(itms.join('.'))); 
                  if (uri.port) {
                    result.push(this.isNumber(uri.port));
                  }
                  if (uri.pathname && !uri.pathname.endsWith('/')) {
                    result.push(uri.pathname.endsWith('/'));
                  }
                } else {
                  result.push(/^((?:https?:\/\/)?(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}(?::\d+)?(?:\/(?:[^\/]+\/)*[^\/]*)?(?:,\s*)?)+\/$/.test(item));
                }
              } catch {
                result.push(/^((?:https?:\/\/)?(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}(?::\d+)?(?:\/(?:[^\/]+\/)*[^\/]*)?(?:,\s*)?)+\/$/.test(item));
              }
            }
          })
          return result.every(v => v === true)
        },
        source_validator(val) {
          if (val) {
            if (val.includes("127.0.0.1")) {
              return false;
            }
            // the value must be ended with a comma
            return this.checkIPFormat(val);
          }
          return val;
        },
        path_validator(val) {
            return val == '' || /^(\^)?\/?([\w-.~*]+\/?)+(\$)?$/.test(val)
        }
    }
}

export default DomainValidationMixin;
