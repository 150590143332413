<!-- xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com -->
<template>
  <div
       @dragenter="dragEnter"
       @dragover="dragEnter"
       @drop="_drop"
       style="position: relative;">
    <CJumbotron>
      <h1 class="display-3">{{ $t('domain.BatchImportOfDomainNames') }}</h1>

      <p>{{ $t('message.batch_domain_import') }} </p>

      <button
          :disabled="!data.length"
          class="btn btn-outline-primary"
          @click="_export"
      >
        <CIcon name="cil-cloud-download"/>&nbsp;
        {{ $t('DownloadImportFile') }}
      </button>

      <button
          class="btn btn-primary"
          style="margin-left: 8px;"
          @click="handleSelectFile"
      >
        <CIcon name="cil-cloud-upload"/>&nbsp;
        {{ $t('UploadImportFile') }}
      </button>

      <CSelect style="display: inline-block; margin-left: 5px;"
               :value="cname"
               @change="onChangeSubscription"
               :options="subscriptions">
      </CSelect>

      <input type="file" style="display: none;"
             @change="_change"
             ref="fileInput">

      <p style="margin-top: 8px;">{{ $t('message.EditFileDescription') }}： </p>
      <ol>
        <li>{{ $t('message.BatchImportDescriptionItem1') }}</li>
        <li>{{ $t('message.BatchImportDescriptionItem2') }}</li>
        <li>{{ $t('message.BatchImportDescriptionItem3') }}</li>
        <li>{{ $t('message.BatchImportDescriptionItem4') }}</li>
      </ol>

    </CJumbotron>

    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid"/>
          {{ $t('FileExample') }}
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
            :items="data"
            :fields="fields"
            :items-per-page="100"
            pagination
        >
          <template #remarks="{item}">
            <td v-if="item._errors">
              <ul v-if="item._errors.length > 1" class="error-list">
                <li v-for="e in item._errors" :key="e">
                  <CIcon name="cil-warning" />&nbsp;
                  {{ e }}
                </li>
              </ul>
              <span v-else-if="item._errors.length === 1">
                <CIcon name="cil-warning" />&nbsp;
                {{ item._errors[0] }}
              </span>
            </td>
            <td v-else-if="item._result">
              <CIcon name="cil-check-circle" />&nbsp;
              {{ item._result }}
            </td>
            <td v-else-if="item.saving_shadow_domains">
              <CSpinner size="sm"/>
              {{ $t('message.SavingShadowDomains') }}
            </td>
            <td v-else-if="item.saving_domains">
              <CSpinner size="sm"/>
              {{ $t('message.SavingDomains') }}
            </td>
            <td v-else-if="item.loading">
              <CSpinner size="sm"/>
              {{ $t('processing') }}
            </td>
            <td v-else-if="item.redeploy">
              <CSpinner size="sm"/>
              {{ $t('message.RedeployingDomain') }}
            </td>
            <td v-else-if="item.onqueue">
              <CSpinner size="sm"/>
            </td>
            <td v-else>
              &nbsp;
            </td>
          </template>
          <template #status={item}>
            <td>
              {{ $t(item.status) }}
            </td>
          </template>
          <template #auto_generate_cert="{item}">
            <td>
              {{ item.auto_generate_cert }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>

    <div class="row">
      <div class="col-xs-12"></div>
    </div>
    <div class="row">
      <div class="col-xs-12">

      </div>
    </div>

    <div class="drag-drop-indicator" :class="{ activeDrag: hasDraggedItem }"></div>
  </div>
</template>

<style scoped>
  ul.error-list {
    list-style: none;
    padding-left: 0;
  }

  .drag-drop-indicator.activeDrag {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: steelblue;
    opacity: 0.2;
  }
</style>

<script>
import XLSX from "xlsx";
import { isEmpty } from "lodash";
import axios from "@/plugins/axios.js";
import {Websocket} from "@/plugins/websocket";
import {$i18n} from '@/plugins/i18n';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import {ExportDomainMixin, COLUMN_KEYS} from '@/mixins/ExportDomainMixin';
import {deactivateDomain, getDomain, resumeDomain, subscriptionPlan} from '@/utilities/api';
import {DomainAction} from '@/utilities/websocket';
import {LIMIT_BATCH_DEPLOY} from '@/utilities/batch';
import {MULTIPLE_VALUES_SPLITTER} from '@/utilities/constants';

export default {
  mixins: [DomainValidationMixin, ExportDomainMixin],
  mounted() { 
    this.openWs();
    this.listenWs();
    this.fetchSubscriptions();
  },
  beforeDestroy() {
    this.closeWs();
  },
  data() {
    return {
      message: "",
      domain_ws: null,
      hasDraggedItem: false,
      clearDragFlagRef: null,
      queueCreate: [],
      onProgressCreate: [],
      queueDelete: [],
      onProgressDelete: [],
      subscriptions: [],
      mutatedData: [],
      importClosePageAlert: false,
      messageTimeout:8500,
      lastMessageTime: null,
      resumeDomainCount: 0,
      deleteDomainCount: 0,
      deactivateDomainCount: 0
    };
  },
  computed: {
    data: {
      get() {
        if (this.mutatedData.length === 0) {
          return [{
            [COLUMN_KEYS.NAME]: 'v03.suyuncdn.com',
            [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8',
            [COLUMN_KEYS.SHADOW_DOMAINS]: 'xyz.suyuncdn.com shadow.suyuncdn.com',
            [COLUMN_KEYS.STATUS]: this.$t('online'),
            [COLUMN_KEYS.NOTES]: 'Example notes',
            [COLUMN_KEYS.AUTO_GENERATE_CERT]: false
          },
          {
            [COLUMN_KEYS.NAME]: 'v04.suyuncdn.com',
            [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8',
            [COLUMN_KEYS.SHADOW_DOMAINS]: '',
            [COLUMN_KEYS.STATUS]: this.$t('disabled'),
            [COLUMN_KEYS.NOTES]: 'Disabled temporarily',
            [COLUMN_KEYS.AUTO_GENERATE_CERT]: false
          },
          {
            [COLUMN_KEYS.NAME]: 'v05.suyuncdn.com',
            [COLUMN_KEYS.ORIGIN_IP]: '8.8.8.8,8.8.4.4',
            [COLUMN_KEYS.SHADOW_DOMAINS]: '',
            [COLUMN_KEYS.STATUS]: this.$t('deleted'),
            [COLUMN_KEYS.NOTES]: '',
            [COLUMN_KEYS.AUTO_GENERATE_CERT]: false
          }]
        }
        return this.mutatedData;
      },
      set(data) {
        this.mudatedData = data;
      }
    },
    cname: {
      get() {
        return this.$store.state.cname;
      },
      set(value) {
        this.$store.state.cname = value;
      }
    },
    is_domain_ws_open() {
      return this.domain_ws.isOpen();
    }
  }, 
  watch: {
    is_domain_ws_open() {
      if (this.is_domain_ws_open) {
        this.listenWs();
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.importClosePageAlert) {
      const answer = window.confirm($i18n('ImportClosePageAlert'));
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
  methods: {
    async onChangeSubscription($event) {
      this.cname = $event.target.value;
    },
    async fetchSubscriptions() {
      let response;
      try {
        response = await subscriptionPlan();
      } catch (errors) {
        errors.forEach((message) => {
          this.flash(message, 'error', {"timeout": 5000});
        });
        return;
      }
      this.subscriptions = response.data.map(item => {
        const cname = item.cname + "." + item.czone;
        return {value: cname, label: cname}}
      );
    },
    openWs() {
      this.domain_ws = new Websocket(`domain/`);
    },
    listenWs() { 
      this.domain_ws.onopen = function(event) {
        console.log("Successfully connected to the domain websocket server...")
      }

      this.domain_ws.onMessage = function(event, result) {
        // Get the specific domain on list filter by the websocket result
        let domain = this.data.find(d => d.name === result.data.domain.name);

        // Showing the error message from backend and continue the parallel process
        if (result.data.hasOwnProperty('error')) {
          if (domain) {
              this.onProgressCreate = this.onProgressCreate.filter(item => item.name !== domain.name);
              let domain_deploy = this.queueCreate.shift();
              if (domain_deploy) {
                this.onProgressCreate.push(domain_deploy);
                this.saveOrResume(domain_deploy);
              }
              this.onProgressDelete = this.onProgressDelete.filter(item => item.name !== domain.name);
              domain_deploy = this.queueDelete.shift();
              if (domain_deploy) {
                this.onProgressDelete.push(domain_deploy);
                this.deleteDomain(domain_deploy);
              }
          }

          const errors = [];
          if (result.data.error instanceof Object) {
            for (let key in result.data.error) {
              if (key == "success" && result.data.error[key] == false) {
                continue;
              }
              if (key == 'shadows'){
                for (let value in result.data.error[key]) {
                  let data = result.data.error[key][value].name[0].charAt(0).toUpperCase() + result.data.error[key][value].name[0].slice(1);
                  if (!errors.includes(data)) {
                    errors.push(data);
                  }
                }
              } else {
                errors.push(result.data.error[key] + '');
              }
              // Force array to string
            }
          } else if (result.data.error.message) {
              errors.push(result.data.error.message);
          } else {
              errors.push(result.data.error);
          }
          this.updateStatus(domain, COLUMN_KEYS.ERRORS, errors);
          this.lastMessageTime = Date.now()
          setTimeout(this.checkMessageTimeout, this.messageTimeout);
          return;
        }

        // Handle the success condition
        if (domain && result.data.dp.success) {
          if (result.data.dp.result.success) {
            if (result.action === DomainAction.DOMAIN_CREATE || result.action === DomainAction.DOMAIN_REDEPLOY) {
              this.onProgressCreate = this.onProgressCreate.filter(item => item.name !== domain.name);
              const domain_deploy = this.queueCreate.shift();
              if (domain_deploy) {
                this.onProgressCreate.push(domain_deploy);
                this.saveOrResume(domain_deploy);
              }
              this.updateStatus(domain, COLUMN_KEYS.RESULT, this.$t('message.domain_created'));
            } else if (result.action === DomainAction.DOMAIN_DELETE) {
              this.onProgressDelete = this.onProgressDelete.filter(item => item.name !== domain.name);
              const domain_deploy = this.queueDelete.shift();
              if (domain_deploy) {
                this.onProgressDelete.push(domain_deploy);
                this.deleteDomain(domain_deploy);
              }
              this.updateStatus(domain, COLUMN_KEYS.RESULT, this.$t('message.domain_deleted'));
            } else if (result.action === DomainAction.DOMAIN_DEACTIVATE) {
               this.updateStatus(domain, COLUMN_KEYS.RESULT, this.$t('message.domain_disabled'));
            }
          } else {
            if (result.action === DomainAction.DOMAIN_CREATE || result.action === DomainAction.DOMAIN_REDEPLOY) {
              this.updateStatus(domain, "loading", false);
              this.updateStatus(domain, "saving_shadow_domains", false);
              this.updateStatus(domain, "redeploy", true);
              this.sendDeploy(DomainAction.DOMAIN_REDEPLOY, [domain.name]);
            } else if (result.action === DomainAction.DOMAIN_DELETE) {
              this.deleteDomain(domain);
            }
          }
        }
        this.lastMessageTime = Date.now()
        setTimeout(this.checkMessageTimeout, this.messageTimeout);
      }.bind(this)
    },
    checkMessageTimeout() {
      const currentTime = Date.now();
      const elapsedTime = currentTime - this.lastMessageTime;
      if (elapsedTime >= this.messageTimeout) {
          this.flash(this.$t('message.UploadCompleted'), 'success', {timeout: 3000});
          this.importClosePageAlert = false
        }
    },
    closeWs() {
      this.domain_ws.connection.close();
    },
    sendDeploy(action, domains) {
      if (Array.isArray(domains)) {
        this.domain_ws.sendMessage(action, {domains: domains});
      } else {
        this.domain_ws.sendMessage(action, domains);
      }
    },
    async startImport() { 
      this.importClosePageAlert = true
      this.message = this.$t('message.upload_in_progress');

      for (let row of this.data) {
        const status = row[COLUMN_KEYS.STATUS].toLowerCase();
        
        // Force to lower case domain and shadow domain.
        row[COLUMN_KEYS.NAME] = row[COLUMN_KEYS.NAME].toLowerCase();
        row[COLUMN_KEYS.SHADOW_DOMAINS] = row[COLUMN_KEYS.SHADOW_DOMAINS].toLowerCase();

        if (status === 'disabled') {
          await this.deactivateDomain(row);
        } else if (status === 'deleted') {
          this.queueDelete.push(row);
        } else {
          this.queueCreate.push(row);
        }
        this.updateStatus(row, "onqueue", true);
      }

      if (this.queueDelete.length) {
        this.deleteDomainCount = this.queueDelete.length
        this.onProgressDelete = this.queueDelete.splice(0, LIMIT_BATCH_DEPLOY);
        this.onProgressDelete.forEach(async (row) => {
          await this.deleteDomain(row);
        });
      }

      if (this.queueCreate.length) {
        this.resumeDomainCount = this.queueCreate.length
        this.onProgressCreate = this.queueCreate.splice(0, LIMIT_BATCH_DEPLOY);
        this.onProgressCreate.forEach(async (row) => {
          await this.saveOrResume(row);
        });
      }

      this.flash(this.$t('message.upload_in_progress'), 'success', {timeout: 3000});
    },
    updateStatus(row, status, message) {
      if (row) {
        const _data = this.data.map((datum) => {
          if (datum._id === row._id) {
            const _datum = {...datum};
            if (!_datum[status]) {
              _datum[status] = {};
            }

            _datum[status] = message;

            return _datum;
          }

          return datum;
        });

        this.mutatedData = _data;
      }
    },
    async saveOrResume(row) { 
      const domainName = row[COLUMN_KEYS.NAME].trim();
      const rawShadowDomain = (row[COLUMN_KEYS.SHADOW_DOMAINS] || '').trim();
      const autoGenerateCert = row[COLUMN_KEYS.AUTO_GENERATE_CERT] === 'true';
      let shadowDomains = rawShadowDomain.length ? rawShadowDomain.split(MULTIPLE_VALUES_SPLITTER) : [];

      let shouldSave = false;
      let response;
      try {
        response = await getDomain(domainName);
      } catch (e) {
        // domain does not exist so do a save
        shouldSave = true;
      }

      if (shouldSave) {
        this.updateStatus(row, "loading", true);

        if (shadowDomains.length) {
          const shadowDomainValidationErrors = [];
          for (let s of shadowDomains) {
            const isSameAsMainDomain = s === domainName;
            
            if (isSameAsMainDomain) {
              shadowDomainValidationErrors.push(this.$t('message.ShadowDomainSameAsMain', [s]));
            }
            else if (!this.shadow_domain_validator(s)) {
              shadowDomainValidationErrors.push(this.$t('message.InvalidShadowDomains', [s]));
            }
          }

          if (shadowDomainValidationErrors.length) {
            this.updateStatus(row, COLUMN_KEYS.ERRORS, shadowDomainValidationErrors);
            return;
          }
          else {
            this.updateStatus(row, "saving_shadow_domains", true);
            console.log("Saving shadow domains");
          }
        }
        else {
          this.updateStatus(row, "saving_domains", true);
          console.log("Saving domains");
        }
        shadowDomains = shadowDomains.map(item => {
          return {name: item};
        });
        const domain = {
            "name": (row[COLUMN_KEYS.NAME] || '').trim(),
            "shadows": shadowDomains,
            "sourceConfig": {"no_www": false, "full_url": (row[COLUMN_KEYS.ORIGIN_IP] || '').trim()},
            "_cname": this.cname,
            "notes": (row[COLUMN_KEYS.NOTES || '']).trim(),
            "sslConfig": {"isAutoGenerateCertificate": autoGenerateCert, "enabled": autoGenerateCert}
        }
        this.sendDeploy(DomainAction.DOMAIN_CREATE, domain);
        return;
      }

      const domain = response.data;
      const shouldReactivate = true;

      if (shouldReactivate) {
        let resumeResponse;
        
        const resumeData = {};
        if (row[COLUMN_KEYS.NOTES]) {
          resumeData.notes = row[COLUMN_KEYS.NOTES];
        }
        try {
          resumeResponse = await resumeDomain(domainName, resumeData);
        } catch (errors) {
          if (typeof(errors) != String) {
            errors = [errors[0].replace('0.', '')]
          }
          this.updateStatus(row, COLUMN_KEYS.ERRORS, errors);
          this.onProgressCreate = this.onProgressCreate.filter(item => item.name !== row.name);
          const domain_deploy = this.queueCreate.shift();
          if (domain_deploy) {
            this.onProgressCreate.push(domain_deploy);
            this.saveOrResume(domain_deploy);
          }
          return;
        }

        if (resumeResponse.status == 202) {
          this.updateStatus(row, COLUMN_KEYS.RESULT, this.$t('message.domain_reactivated'));
          this.resumeDomainCount--;
        } else {
          this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.failed_to_reactivate_domain')]);
          this.resumeDomainCount--;
        }

        this.onProgressCreate = this.onProgressCreate.filter(item => item.name !== row.name);
        const domain_deploy = this.queueCreate.shift();
        if (domain_deploy) {
          this.onProgressCreate.push(domain_deploy);
          this.saveOrResume(domain_deploy);
        }

        if (this.resumeDomainCount == 0 && this.deleteDomainCount == 0 && this.deactivateDomainCount == 0) {
          this.flash(this.$t('message.UploadCompleted'), 'success', {timeout: 3000});
          this.importClosePageAlert = false
        }
        const domain = {
            "name": (row[COLUMN_KEYS.NAME] || '').trim(),
            "shadows": shadowDomains,
            "sourceConfig": {"no_www": false, "full_url": (row[COLUMN_KEYS.ORIGIN_IP] || '').trim()},
            "_cname": this.cname,
            "notes": (row[COLUMN_KEYS.NOTES || '']).trim(),
            "sslConfig": {"isAutoGenerateCertificate": autoGenerateCert, "enabled": autoGenerateCert},
        }
        this.sendDeploy(DomainAction.DOMAIN_CREATE, domain);
        return;
      }

      this.onProgressCreate = this.onProgressCreate.filter(item => item.name !== row.name);
      const domain_deploy = this.queueCreate.shift();
      if (domain_deploy) {
        this.onProgressCreate.push(domain_deploy);
        this.saveOrResume(domain_deploy);
      }

      this.updateStatus(row, COLUMN_KEYS.ERRORS, [this.$t('message.domain_already_exist')]);
    },
    async deactivateDomain(row) {
      this.updateStatus(row, "loading", true);

      let response;
      try {
        const data = {};
        const autoGenerateCert = row[COLUMN_KEYS.AUTO_GENERATE_CERT] === 'true';
        const domainName = row[COLUMN_KEYS.NAME].trim();
        const rawShadowDomain = (row[COLUMN_KEYS.SHADOW_DOMAINS] || '').trim();
        let shadowDomains = rawShadowDomain.length ? rawShadowDomain.split(MULTIPLE_VALUES_SPLITTER) : [];
        
        if (shadowDomains.length) {
          const shadowDomainValidationErrors = [];
          for (let s of shadowDomains) {
            const isSameAsMainDomain = s === domainName;
            
            if (isSameAsMainDomain) {
              shadowDomainValidationErrors.push(this.$t('message.ShadowDomainSameAsMain', [s]));
            }
            else if (!this.shadow_domain_validator(s)) {
              shadowDomainValidationErrors.push(this.$t('message.InvalidShadowDomains', [s]));
            }
          }

          if (shadowDomainValidationErrors.length) {
            this.updateStatus(row, COLUMN_KEYS.ERRORS, shadowDomainValidationErrors);
            return;
          }
        }

        shadowDomains = shadowDomains.map(item => {
          return {name: item};
        });
        const domain = {
            "name": (row[COLUMN_KEYS.NAME] || '').trim(),
            "shadows": shadowDomains,
            "sourceConfig": {"no_www": false, "full_url": (row[COLUMN_KEYS.ORIGIN_IP] || '').trim()},
            "_cname": this.cname,
            "notes": (row[COLUMN_KEYS.NOTES || '']).trim(),
            "sslConfig": {"isAutoGenerateCertificate": autoGenerateCert, "enabled": autoGenerateCert},
            "is_import": true
        }
        this.sendDeploy(DomainAction.DOMAIN_DEACTIVATE, {
            domains: [domain],
            is_import: true
         });
      } catch (errors) {
        this.updateStatus(row, COLUMN_KEYS.ERRORS, errors);
        return;
      }
    },
    async deleteDomain(row) {
      this.updateStatus(row, "loading", true);

      this.sendDeploy(DomainAction.DOMAIN_DELETE, [(row[COLUMN_KEYS.NAME] || '').trim()]);
    },
    handleSelectFile() {
      // reset selection
      this.$refs.fileInput.value = '';

      this.$refs.fileInput.click();
    },
    dragEnter(evt) {
      evt.stopPropagation();
      evt.preventDefault();

      this.clearDragFlag();

      this.hasDraggedItem = true;
      this.clearDragFlagRef = setTimeout(() => {
        this.hasDraggedItem = false;
      }, 3000);
    },
    clearDragFlag() {
      if (this.clearDragFlagRef) {
        clearTimeout(this.clearDragFlagRef);
        this.clearDragFlagRef = null;
      }
    },
    _drop(evt) {
      evt.stopPropagation();
      evt.preventDefault();

      this.clearDragFlag();
      this.hasDraggedItem = false;

      const files = evt.dataTransfer.files;
      if (files && files[0]) this._file(files[0]);
    },
    _change(evt) {
      const files = evt.target.files;
      if (files && files[0]) this._file(files[0]);
    },
    _export() {
      this.setExportImportLang();
      this._exportDomainData(this.data);
    },
    _file(file) {
      if (!this.domain_ws.isOpen()) {
        this.flash(this.$t('message.WebsocketNotOpen'), 'error', {'timeout': 8000});
        return;
      }

      const reader = new FileReader();

      reader.onload = e => {

        function cleanInput(val) {
          val = String(val);
          return (val || '').trim();
        }

        this.setExportImportLang();
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {type: "binary"});

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const resultJson = XLSX.utils.sheet_to_json(ws, {header: this.exportFields.map((f) => f.key)});
        if (resultJson.length < 2) {
          this.flash(this.$t('message.empty_import_file'), 'error', { "timeout": 5000 });
          return;
        }

        const [_headerRow, ...data] = resultJson;

        const hasDomainColumn = _headerRow[COLUMN_KEYS.NAME] === this.$t("domain.Domain");
        const hasOriginIpColumn = _headerRow[COLUMN_KEYS.ORIGIN_IP] === this.$t("domain.OriginIP");
        const hasStatusColumn = _headerRow[COLUMN_KEYS.STATUS] === this.$t("domain.Status");

        if (!hasDomainColumn || !hasOriginIpColumn || !hasStatusColumn) {
          this.flash(this.$t('message.import_file_no_header'), 'error', { "timeout": 8000 });
          return;
        }

        const statusAcceptedValues = [this.$t('online'), this.$t('disabled'), this.$t('deleted')].map((v) => v.toLowerCase());
        const statusValidationPassed = data.every((datum) => {
          const datumElement = datum[COLUMN_KEYS.STATUS];
          if (!datumElement)
            return false;

          return statusAcceptedValues.includes(cleanInput(datumElement).toLowerCase());
        });

        if (!statusValidationPassed) {
          this.flash(this.$t('message.allowed_status_only') + ' ' + statusAcceptedValues.join(', '), 'error', { "timeout": 8000 });
          return;
        }

        const cellsValidationPassed = data.every((datum) => {
          const shouldRequestOriginIpAndGroup = cleanInput(datum[COLUMN_KEYS.STATUS]).toLowerCase() === 'online';
          const isDomainValid = this.domain_validator(cleanInput(datum[COLUMN_KEYS.NAME]));

          if (shouldRequestOriginIpAndGroup) {
            return isDomainValid &&
                this.source_validator(cleanInput(datum[COLUMN_KEYS.ORIGIN_IP]));
          }

          return isDomainValid;
        });
        if (!cellsValidationPassed) {
          this.flash(this.$t('message.cell_value_validation_failed'), 'error', { "timeout": 5000 });
          return;
        }

        this.mutatedData = data.map((datum) => {
          datum._id = Math.random();
          datum[COLUMN_KEYS.NAME] = cleanInput(datum[COLUMN_KEYS.NAME]);
          datum[COLUMN_KEYS.ORIGIN_IP] = datum[COLUMN_KEYS.ORIGIN_IP] ? cleanInput(datum[COLUMN_KEYS.ORIGIN_IP]) : '';
          datum[COLUMN_KEYS.SHADOW_DOMAINS] = datum[COLUMN_KEYS.SHADOW_DOMAINS] ? cleanInput(datum[COLUMN_KEYS.SHADOW_DOMAINS]) : '';
          datum[COLUMN_KEYS.NOTES] = datum[COLUMN_KEYS.NOTES] ? cleanInput(datum[COLUMN_KEYS.NOTES]) : '';
          datum[COLUMN_KEYS.AUTO_GENERATE_CERT] = cleanInput(datum[COLUMN_KEYS.AUTO_GENERATE_CERT])

          return datum;
        });

        this.startImport();
        this.resetLang();
      };
      reader.readAsBinaryString(file);
    }
  }
};
</script>
