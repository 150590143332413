import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Action } from '@/utilities/websocket'

const rootDomain = location.hostname.split('.').reverse().splice(0,2).reverse().join('.')

function generateRandom(min, max, step) {
  const randomNum = min + Math.random() * (max - min);
  return Math.round(randomNum / step) * step;
}

export function Websocket(path, base = null) {
  const url = new URL(path,
    (base || process.env.VUE_APP_WSS || 'wss://localhost:3000/ws/').replace("suyuncdn3.com", rootDomain )
  )

  const urlProvider = async () => {
    const search_params = new URLSearchParams({token: store.state.security.auth.token, realm: store.state.security.auth.realm})
    const params = await search_params.toString()
    return `${url.href}?${params}`
  }

  this.connection = new ReconnectingWebSocket(urlProvider, [], {connectionTimeout: generateRandom(5000, 15000, 50), reconnectionDelayGrowFactor: 2})

  this.sendMessage = (action, data) => {
    const context = {
      action: action,
      data: data
    }
    return this.connection.send(JSON.stringify(context))
  }

  this.onMessage = (event, result) => {
    // TODO
    console.log(result)

  }

  this.isOpen = () => {
    return this.connection.readyState === WebSocket.OPEN;
  }

  this.connection.onmessage = (event) => {
    const result = JSON.parse(event.data)
    if (result.action === Action.AUTH_ERROR) {
      Vue.prototype.$flashStorage.flash(result.data.error, 'error', {'timeout': 8000})
      setTimeout(() => {
        router.go()
      }, 8000)
    }
    this.onMessage(event, result)
  }
}
