export const Action = {
  AUTH_ERROR: -1,
  WEBSOCKET_PARSE: 0
}

export const DomainAction = {
  WEBSOCKET_PARSE: 0,
  DOMAIN_REDEPLOY: 1,
  CLEAR_CACHE: 2,
  DOMAIN_DEACTIVATE: 3,
  DOMAIN_RESUME: 4,
  DOMAIN_DELETE: 5,
  DOMAIN_CREATE: 6,
  BATCH_REDEPLOY_DOMAIN: 7,
}

export const CertAction = {
  WEBSOCKET_PARSE: 0,
  CERT_ISSUE: 1
}

export const ShadowDomainAction = {
  CNAME_CHECK_ERROR: -3,
  WEBSOCKET_PARSE: 0,
  SHADOW_DOMAIN: 1
}
